import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

const HeaderTextWrapper = styled.h1`
  font-size: 40px;
  font-weight: 400;

  @media only screen and (max-width: 640px) {
    font-size: 36px;
  }
`
type Props = {
  children: ReactNode
}

const HeaderText: React.FC<Props> = ({ children }) => (
  <HeaderTextWrapper>{children}</HeaderTextWrapper>
)

export default HeaderText
