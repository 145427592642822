import React from 'react'
import Helmet from 'react-helmet'
import { graphql, PageProps } from 'gatsby'

import Layout from '../../layouts'
import HeaderText from '../../components/HeaderText'
import HeaderWrapper from '../../components/HeaderWrapper'

interface NotFoundPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
      }
    }
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>{`404 Page Not Found | ${data.site.siteMetadata.title}`}</title>
      </Helmet>
      <HeaderWrapper>
        <HeaderText>{'Uh oh!'}</HeaderText>
        <h3>
          {
            "The page you're looking for doesn't seem to exist. Sorry about that!"
          }
        </h3>
      </HeaderWrapper>
      <div style={{ height: '140px' }} />
    </Layout>
  )
}

export default NotFoundPage
